import Vue from 'vue'
import Router from 'vue-router'
import HellWorld from '@/components/inlet.vue'
import LinuxSys from '@/components/linux-sys1.vue'
import LinuxCmd from '@/components/linux-cmd2.vue'
import LinuxDf from '@/components/linux-df3.vue'
import LinuxYum from '@/components/linux-yum5.vue'
import LinuxChmod from '@/components/linux-chmod6.vue'
import LinuxShell from '@/components/linux-shell7.vue'
import linuxNetwork from '@/components/linux-network8.vue'
import networkSw from '@/components/network-sw9.vue'
import networkRoute from '@/components/network-route10.vue'
import linuxFirewalld from '@/components/linux-firewalld11.vue'
import networkBt from '@/components/network-bt12.vue'
import networkDns from '@/components/network-dns13.vue'
import linuxDhcp from '@/components/linux-dhcp14.vue'
import openstackKvm from '@/components/openstack-kvm15.vue'
import middlewareRedis from '@/components/middleware-redis17.vue'
import middlewareNginx from '@/components/middleware-nginx18.vue'
import openstackAnsible from '@/components/openstack-ansible19.vue'
import middlewareKeeplived from '@/components/middleware-keeplived20.vue'
import middlewareHnl from '@/components/middleware-hnl21.vue'
import openstackGlusterfs from '@/components/openstack-glusterfs22.vue'
import middlewareKafka from '@/components/middleware-kafka23.vue'
import middlewareZookeeper from '@/components/middleware-zookeeper24.vue'
import middlewareElk from '@/components/middleware-elk25.vue'
import openstackArchitecture from '@/components/openstack-architecture26.vue'
import openstackImage from '@/components/openstack-inm27.vue'
import openstackGpmall from '@/components/openstack-gpmall28.vue'
import openstackKeystone from '@/components/openstack-keystone29.vue'
import openstackGlance from '@/components/openstack-glance30.vue'
import dockerBase from '@/components/docker-base31.vue'
import dockerImage from '@/components/docker-image32.vue'
import dockerCompose from '@/components/docker-compose33.vue'
import k8sInstall from '@/components/k8s-install34.vue'
import k8sKubectl from '@/components/k8s-kubectl35.vue'
import k8sPod from '@/components/k8s-pod36.vue'
import k8sService from '@/components/k8s-service37.vue'
import k8sCicd from '@/components/k8s-cicd38.vue'
import k8sIstio from '@/components/k8s-istio39.vue'
import kubeEdge from '@/components/kube-edge40.vue'
import k8sScpv from '@/components/k8s-scpv41.vue'
import dockerNetwork from '@/components/docker-network42.vue'
import openstackSdn from '@/components/openstack-sdn43.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/43-openstack-sdn',
      name: 'openstack-sdn',
      component: openstackSdn
    },
    {
      path: '/42-docker-network',
      name: 'docker-network',
      component: dockerNetwork
    },
    {
      path: '/41-k8s-scpv',
      name: 'k8s-scpv',
      component: k8sScpv
    },
    {
      path: '/40-kube-edge',
      name: 'kube-edge',
      component: kubeEdge
    },
    {
      path: '/39-k8s-istio',
      name: 'k8s-istio',
      component: k8sIstio
    },
    {
      path: '/38-k8s-cicd',
      name: 'k8s-cicd',
      component: k8sCicd
    },
    {
      path: '/37-k8s-service',
      name: 'k8s-service',
      component: k8sService
    },
    {
      path: '/36-k8s-pod',
      name: 'k8s-pod',
      component: k8sPod
    },
    {
      path: '/35-k8s-kubectl',
      name: 'k8s-kubectl',
      component: k8sKubectl
    },
    {
      path: '/34-k8s-install',
      name: 'k8s-install',
      component: k8sInstall
    },
    {
      path: '/33-docker-compose',
      name: 'docker-compose',
      component: dockerCompose
    },
    {
      path: '/32-docker-image',
      name: 'docker-image',
      component: dockerImage
    },
    {
      path: '/31-docker-base',
      name: 'docker-base',
      component: dockerBase
    },
    {
      path: '/30-openstack-glance',
      name: 'openstack-glance',
      component: openstackGlance
    },
    {
      path: '/29-openstack-keystone',
      name: 'openstack-keystone',
      component: openstackKeystone
    },
    {
      path: '/28-openstack-gpmall',
      name: 'openstack-gpmall',
      component: openstackGpmall
    },
    {
      path: '/',
      name: 'HellWorld',
      component: HellWorld
    },
    {
      path: '/1-linux-sys',
      name: 'linux-sys',
      component: LinuxSys
    },
    {
      path: '/2-linux-cmd',
      name: 'linux-cmd',
      component: LinuxCmd
    },
    {
      path: '/3-linux-df',
      name: 'linux-df',
      component: LinuxDf
    },
    {
      path: '/5-linux-yum',
      name: 'linux-yum',
      component: LinuxYum
    },
    {
      path: '/6-linux-chmod',
      name: 'linux-chmod',
      component: LinuxChmod
    },
    {
      path: '/7-linux-shell',
      name: 'linux-shell',
      component: LinuxShell
    },
    {
      path: '/8-linux-network',
      name: 'linux-network',
      component: linuxNetwork
    },
    {
      path: '/9-network-sw',
      name: 'network-sw',
      component: networkSw
    },
    {
      path: '/10-network-route',
      name: 'network-route',
      component: networkRoute
    },
    {
      path: '/11-linux-firewalld',
      name: 'linux-firewalld',
      component: linuxFirewalld
    },
    {
      path: '/12-network-bt',
      name: 'network-bt',
      component: networkBt
    },
    {
      path: '/13-network-dns',
      name: 'network-dns',
      component: networkDns
    },
    {
      path: '/14-linux-dhcp',
      name: 'linux-dhcp',
      component: linuxDhcp
    },
    {
      path: '/15-openstack-kvm',
      name: 'openstack-kvm',
      component: openstackKvm
    },
    {
      path: '/17-middleware-redis',
      name: 'middleware-redis',
      component: middlewareRedis
    },
    {
      path: '/18-middleware-nginx',
      name: 'middleware-nginx',
      component: middlewareNginx
    },
    {
      path: '/19-openstack-ansible',
      name: 'openstack-ansible',
      component: openstackAnsible
    },
    {
      path: '/20-middleware-keeplived',
      name: 'middleware-keeplived',
      component: middlewareKeeplived
    },
    {
      path: '/21-middleware-hnl',
      name: 'middleware-hnl',
      component: middlewareHnl
    },
    {
      path: '/22-openstack-glusterfs',
      name: 'openstack-glusterfs',
      component: openstackGlusterfs
    },
    {
      path: '/23-middleware-kafka',
      name: 'middleware-kafka',
      component: middlewareKafka
    },
    {
      path: '/24-middleware-zookeeper',
      name: 'middleware-zookeeper',
      component: middlewareZookeeper
    },
    {
      path: '/25-middleware-elk',
      name: 'middleware-elk',
      component: middlewareElk
    },
    {
      path: '/26-openstack-architecture',
      name: 'openstack-architecture',
      component: openstackArchitecture
    },
    {
      path: '/27-openstack-inm',
      name: 'openstack-inm',
      component: openstackImage
    }
  ]
})
